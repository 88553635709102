<template>
  <div :style="style" :class="'transition-' + wrapClass">
    <transition-group
      name="slide"
      tag="div"
      mode="out-in"
      v-bind:class="$attrs.className"
    >
      <slot></slot>
    </transition-group>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    distance: {
      type: String,
      default: '50px',
    },
    direction: {
      type: String,
      default: 'left', // left,right,top,right
    },
  },
  computed: {
    wrapClass() {
      return ['left', 'right'].includes(this.direction)
        ? 'horizontal'
        : 'vertical'
    },
    style() {
      return ['left', 'top'].includes(this.direction)
        ? `--enter:${this.distance};--leave:-${this.distance}`
        : `--enter:-${this.distance};--leave:${this.distance}`
    },
  },
}
</script>
<style lang="scss" scoped>
.transition-horizontal {
  /deep/.slide {
    &-enter {
      opacity: 0;
      transform: translateX(var(--enter));
      &-active {
        transition: all 0.3s ease-in-out;
        transition-delay: 0.3s;
      }
      &-to {
        opacity: 1;
        transform: translateX(0px);
      }
    }
    &-leave {
      opacity: 0.2;
      transform: translateX(0px);
      &-active {
        transition: all 0.3s ease-in-out;
      }
      &-to {
        opacity: 0;
        transform: translateX(var(--leave));
      }
    }
  }
}
.transition-vertical {
  /deep/.slide {
    &-enter {
      opacity: 0;
      transform: translateY(var(--enter));
      &-active {
        transition: all 0.3s ease-in-out;
        transition-delay: 0.3s;
      }
      &-to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    &-leave {
      opacity: 0.2;
      transform: translateY(0px);
      &-active {
        transition: all 0.3s ease-in-out;
      }
      &-to {
        opacity: 0;
        transform: translateY(var(--leave));
      }
    }
  }
}
</style>
