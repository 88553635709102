<template>
  <section v-loading='isLoading'>
    <el-form
      :model='ruleForm'
      :rules='rules'
      ref='ruleForm'
      label-width='200px'
      class='el-form-wrap'
      size='medium'
    >
      <div class='form__title'>
        基本信息
      </div>
      <el-form-item label='优惠券标识' v-if='ruleForm.guid'>
        <div class='xa-cell'>
          <ClipInput :value='ruleForm.guid' style='width:400px;font-size:12' />
          <el-button
            type='primary'
            style='margin-left:16px;'
            @click="$emit('qrCode', ruleForm.guid)"
          >推广
          </el-button
          >
        </div>
      </el-form-item>
      <el-form-item label='优惠券名称' prop='title'>
        <el-input
          v-model='ruleForm.title'
          maxlength='256'
          placeholder='请输入优惠券名称'
          style='width:250px;'
        />
      </el-form-item>
      <el-form-item label='名称备注' prop='desc'>
        <el-input
          v-model='ruleForm.desc'
          maxlength='512'
          placeholder='仅内部查看'
          style='width:250px;'
        />
      </el-form-item>
      <el-form-item label='发放总量' prop='all_cnt'>
        <el-input
          v-model='ruleForm.all_cnt'
          placeholder='请输入发放总量'
          autocomplete='off'
          style='width:200px;'
          @blur="farmat($event, 'all_cnt', 'Int', 99999999)"
        >
          <template slot='append'>张</template>
        </el-input
        >
      </el-form-item>
      <el-form-item label='适用系统' prop='use_sys'>
        <el-radio-group v-model='ruleForm.use_sys' :disabled='!!ruleForm.guid'>
          <el-radio
            v-for='item in sysList'
            :key='item.value'
            :label='item.value'
          >{{ item.label }}
          </el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label='适用商品' prop='product_type'>
        <div class='xa-txt-placeholder'>指定适用系统中包括的商品</div>
        <el-radio-group v-model='ruleForm.product_type'>
          <el-radio
            v-for='item in productType'
            :key='item.value'
            :label='item.value'
          >{{ item.label }}
          </el-radio
          >
        </el-radio-group>
        <div>
          <transition name='el-fade-in'>
            <div v-if="ruleForm.product_type && ruleForm.product_type != '1'">
              <div class='xa-txt-blue xa-cursor' @click='onSelectProduct'>
                选择商品/修改已选商品
              </div>
              <TransitionGroupSlide>
                <DisplayProductTable
                  key='商品'
                  :calcHeight='true'
                  v-show="need_display_product_type == '商品'"
                  :datas='ruleForm.product'
                  @delete="onDelProduct('商品', $event)"
                />
                <DisplayProductClassify
                  key='商品分类'
                  v-show="need_display_product_type == '商品分类'"
                  :datas='ruleForm.product_category'
                  @delete="onDelProduct('商品分类', $event)"
                />
              </TransitionGroupSlide>
            </div>
          </transition>
        </div>
      </el-form-item>
      <el-form-item label='使用门槛' prop='need_use_limit_content'>
        <div class='xa-cell'>
          <el-radio-group
            class='form-radio-group xa-view'
            v-model='ruleForm.need_use_limit_content'
          >
            <el-radio label='1'>无限制</el-radio>
            <el-radio label='2'>
              <div class='xa-cell'>
                <div>订单满</div>
                <el-input
                  v-model='ruleForm.use_limit_content'
                  :disabled='ruleForm.need_use_limit_content != 2'
                  placeholder=''
                  autocomplete='off'
                  style='width:120px;margin:0 8px;'
                  @blur="farmat($event, 'use_limit_content', 'Float')"
                />
                <div>元</div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label='优惠内容' prop='discount'>
        <div class='form-radio-group xa-view'>
          <div class='xa-cell el-radio'>
            <div>减免</div>
            <el-input
              v-model='ruleForm.discount'
              placeholder=''
              autocomplete='off'
              style='width:120px;margin:0 8px;'
              @blur="farmat($event, 'discount', 'Float')"
            />
            <div>元</div>
          </div>
          <div class='xa-cell el-radio' v-show='can_discount_one_product'>
            <el-checkbox v-model='need_discount_one_product'>
              <div class='xa-cell'>
                <div>每件产品最多优惠</div>
                <el-input
                  v-model='ruleForm.discount_one_product'
                  :disabled='!need_discount_one_product'
                  placeholder=''
                  autocomplete='off'
                  style='width:120px;margin:0 8px;'
                  @blur="farmat($event, 'discount_one_product', 'Float')"
                />
                <div>元</div>
              </div>
            </el-checkbox>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="每件产品限用券数" prop="need_one_product_use_cnt">
        <div class="xa-cell">
          <el-radio-group
            class="form-radio-group xa-view"
            v-model="ruleForm.need_one_product_use_cnt"
          >
            <el-radio label="1">不限数量</el-radio>
            <el-radio label="2">
              <div class="xa-cell">
                <el-input
                  v-model.number="ruleForm.one_product_use_cnt"
                  :disabled="ruleForm.need_one_product_use_cnt != 2"
                  placeholder=""
                  autocomplete="off"
                  style="width:120px;margin-right:8px;"
                />
                <div>张</div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </el-form-item> -->
      <el-form-item label='用券时间' prop='need_use_time'>
        <div class='xa-cell'>
          <el-radio-group
            class='form-radio-group xa-view'
            v-model='ruleForm.need_use_time'
          >
            <el-radio label='1'>
              <el-date-picker
                :disabled='ruleForm.need_use_time != 1'
                v-model='ruleForm.use_at'
                type='datetimerange'
                range-separator='-'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
              >
              </el-date-picker>
            </el-radio>
            <el-radio label='2'>
              <div class='xa-cell'>
                <div>领券当日起</div>
                <el-input
                  v-model='ruleForm.receive_limit_day'
                  :disabled='ruleForm.need_use_time != 2'
                  placeholder=''
                  autocomplete='off'
                  style='width:120px;margin:0 8px;'
                  @blur="farmat($event, 'receive_limit_day', 'Int')"
                />
                <div>天内可用</div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <div class='form__title'>领取和使用规则</div>
      <el-form-item label='领券时间' prop='need_receive_time'>
        <div class='xa-cell'>
          <el-radio-group
            class='form-radio-group xa-view'
            v-model='ruleForm.need_receive_time'
          >
            <el-radio label='1'>
              不限制
            </el-radio>
            <el-radio label='2'>
              <el-date-picker
                :disabled='ruleForm.need_receive_time != 2'
                v-model='ruleForm.receive_at'
                type='datetimerange'
                range-separator='-'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
              >
              </el-date-picker>
            </el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label='每人限领次数' prop='need_receive_limit_cnt'>
        <div class='xa-cell'>
          <el-radio-group
            class='form-radio-group xa-view'
            v-model='ruleForm.need_receive_limit_cnt'
          >
            <el-radio label='1'>不限次数</el-radio>
            <el-radio label='2'>
              <div class='xa-cell'>
                <el-input
                  v-model='ruleForm.receive_limit_cnt'
                  :disabled='ruleForm.need_receive_limit_cnt != 2'
                  placeholder=''
                  autocomplete='off'
                  style='width:120px;margin-right:8px;'
                  @blur="farmat($event, 'receive_limit_cnt', 'Int')"
                />
                <div>次</div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label='转移设置' prop='transfer_type'>
        <div class='xa-cell'>
          <el-radio-group
            class='form-radio-group xa-view'
            v-model='ruleForm.transfer_type'
          >
            <el-radio label='1'>可转移</el-radio>
            <el-radio label='3'>仅支持转移给商家</el-radio>
            <el-radio label='2'>不可转移</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label='激活设置' prop='active_type'>
        <div class='xa-cell'>
          <el-radio-group
            class='form-radio-group xa-view'
            v-model='ruleForm.active_type'
          >
            <el-radio label='0'>不需要激活</el-radio>
            <el-radio label='1'>需要激活</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label='立即使用跳转页面'>
        <div class='xa-cell'>
          <el-input
            v-model='ruleForm.use_url'
            placeholder='请输入链接，例如：https://www.xa.com/'
          />
          <el-popover
            placement='top-end'
            title='小程序中仅支持跳转至已认证的业务域名：'
            width='400'
            trigger='hover'
          >
            <div class='xa-txt-secondary'>
              <p>https://agri.xaircraft.com</p>
              <p>https://cloud.xag.cn</p>
              <p>https://xserivice.xag.cn</p>
              <p>https://xaa.xaircraft.com</p>
              <p>https://knowledge.xa.com</p>
              <p>https://www.xa.com</p>
            </div>
            <i
              slot='reference'
              style='padding-left:16px;'
              class='el-icon-question'
            ></i>
          </el-popover>
        </div>
      </el-form-item>
      <el-form-item label='公开设置'>
        <el-checkbox v-model='ruleForm.is_public'>
          优惠券允许公开领取
        </el-checkbox
        >
      </el-form-item>
      <el-form-item label='其他限制'>
        <el-checkbox v-model='ruleForm.use_limit_original_price'>
          优惠券仅原价购买商品时可用
        </el-checkbox
        >
      </el-form-item>
      <el-form-item label='使用说明'>
        <el-input
          type='textarea'
          v-model='ruleForm.content'
          rows='5'
          placeholder='请输入'
        ></el-input>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
import TransitionGroupSlide from '@/components/xa-form/TransitionGroupSlide.vue'
import ClipInput from '@/components/tool/ClipInput'
// 固定值 表示单品券
const use_limit_type = 2

function getProductType(type) {
  let value = ''
  let options = []
  switch (type) {
    case 2:
      options = [
        {
          label: '指定商品可用',
          value: 2
        }
      ]
      value = 2
      break
    default:
      options = [
        {
          label: '全部商品可用',
          value: 1
        },
        {
          label: '指定商品可用',
          value: 2
        },
        {
          label: '指定商品不可用',
          value: 3
        }
      ]
      break
  }
  return {
    value,
    options
  }
}

export default {
  inject: ['Apis'],
  components: {
    ClipInput,
    DisplayProductTable: () => import('./components/DisplayProductTable.vue'),
    DisplayProductClassify: () =>
      import('./components/DisplayProductClassify.vue'),
    TransitionGroupSlide
  },
  data() {
    const { options, value } = getProductType(use_limit_type)
    const now = new Date()
    return {
      nowDay: new Date(
        `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 00:00:00`
      ),
      sysList: [], // 优惠券适用平台
      isLoading: true,
      createSelectProductKey: Date.now(), // 调用父组件的Create的Selector的一个tag，当tag发生变化，Selector就需要新创建一个
      productType: options,
      need_discount_one_product: false, // 【控制】每件产品最多优惠 额度
      need_display_product_type: '商品', // 【控制】表格是显示 商品 还是商品分类
      ruleForm: {
        use_limit_type,
        title: '',
        desc: '',
        all_cnt: '',
        use_sys: '',
        product_type: value,
        need_use_limit_content: '1', // 【控制】使用门槛
        use_limit_content: '', // 满减金额
        need_one_product_use_cnt: '1', // 【控制】每件产品限用券数 1:不限制，2:有限制
        discount: '', // 减免金额
        discount_one_product: '',
        one_product_use_cnt: '',
        need_use_time: '1', // 【控制】用券时间 1:时间段、2:领后一段时间
        use_start_at: '',
        use_end_at: '',
        use_at: '', // 【表单控件】绑定的值
        receive_limit_day: '',
        need_receive_time: '1', // 【控制】领券时间
        receive_start_at: '',
        receive_end_at: '',
        receive_at: '',
        need_receive_limit_cnt: '1', // 【控制】每人限领次数
        receive_limit_cnt: '',
        transfer_type: '1',
        active_type: '0',
        use_url: '',
        is_public: '', // 1: 非公开 2:公开
        use_limit_original_price: '', // 1:无限制 2:原价购买商品才可使用
        content: '',
        product: [], // 指定商品
        product_category: [] // 指定商品分类
      },
      rules: {
        title: [
          { required: true, message: '请输入优惠券名称', trigger: 'blur' },
          { min: 3, message: '长度不少于3个字符', trigger: 'blur' }
        ],
        all_cnt: [
          { required: true, message: '请输入发放总量', trigger: 'blur' }
        ],
        use_sys: [
          { required: true, message: '请选择适用系统', trigger: 'change' }
        ],
        product_type: [
          { required: true, message: '请选择适用商品', trigger: 'change' }
        ],
        need_use_limit_content: [
          { required: true, message: '请选择使用门槛', trigger: 'change' }
        ],
        discount: [
          { required: true, message: '请填写优惠内容', trigger: 'blur' }
        ],
        need_one_product_use_cnt: [
          { required: true, message: '请填写限制', trigger: 'change' }
        ],
        need_use_time: [
          { required: true, message: '请填写用卷时间', trigger: 'change' }
        ],
        need_receive_time: [
          { required: true, message: '请选择领券时间', trigger: 'change' }
        ],
        need_receive_limit_cnt: [
          { required: true, message: '请选择限制', trigger: 'change' }
        ],
        transfer_type: [
          { required: true, message: '请选择转移设置', trigger: 'change' }
        ],
        active_type: [
          { required: true, message: '请选中激活设置', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    // 单件最多优惠额度
    can_discount_one_product() {
      return [2, 3].includes(parseInt(this.ruleForm.product_type))
    }
  },
  methods: {
    farmat(e, key, type, max) {
      type = type.toLowerCase()
      if (type === 'int') {
        max = max || 99999
        this.ruleForm[key] = Math.min(parseInt(this.ruleForm[key]) || 0, max)
      } else if (type === 'float') {
        max = max || 9999999.99
        var value = this.ruleForm[key] + ''
        if (value.includes('.')) {
          window.console.log(e.target.value, parseFloat(value))
          value = parseFloat(value).toFixed(2) || 0
          if (value === 'NaN') value = 0
          this.ruleForm[key] = Math.min(value || 0, max)
        } else {
          this.farmat(e, key, 'int', max)
        }
      }
    },
    zeroToByEmpty(data, keys) {
      keys.forEach(key => {
        if (data[key] === 0 || data[key] === '0') data[key] = ''
      })
    },
    async onSelectProduct() {
      if (this.ruleForm.use_sys === '')
        return this.$notify.info('请先指定适用系统')
      try {
        const selectResult = await new Promise(resolve => {
          const resolveMsg = list => resolve(list)
          resolveMsg.params = {
            use_sys: this.ruleForm.use_sys, // 商品的平台
            use_limit_type: this.ruleForm.use_limit_type // 券的类型
          }
          resolveMsg.key =
            this.createSelectProductKey + '_' + this.ruleForm.use_sys
          this.$emit('selectProduct', resolveMsg)
        })
        if (this.ruleForm.use_limit_type === 2) {
          // 单品券只支持一个单选一个商品
          this.ruleForm.product.pop()
        }
        this.handleSelectResult(selectResult)
      } catch (error) {
        window.console.warn(error)
      }
    },
    handleSelectResult(selectResult) {
      const { type, results } = selectResult
      this.need_display_product_type = type
      switch (type) {
        case '商品':
          this.ruleForm.product = results
          this.ruleForm.product_category = []
          break
        case '商品分类':
          this.ruleForm.product_category = results
          this.ruleForm.product = []
          break
        default:
          break
      }
    },
    onDelProduct(type, item) {
      switch (type) {
        case '商品':
          this.ruleForm.product.splice(this.ruleForm.product.indexOf(item), 1)
          break
        case '商品分类':
          this.ruleForm.product_category.splice(
            this.ruleForm.product_category.indexOf(item),
            1
          )
          break
        default:
          break
      }
    },
    // 防止是数字类型，会绑定不上
    toBeString(keys, data) {
      keys.forEach(key => (data[key] += ''))
    },
    // 获取【表单】的初始值
    getDefaulValue(defaultData) {
      Object.assign(this.ruleForm, defaultData)
      this.toBeString(['use_sys', 'transfer_type', 'active_type'], this.ruleForm)
      this.need_discount_one_product = defaultData.discount_one_product > 0
      this.ruleForm.need_one_product_use_cnt =
        defaultData.one_product_use_cnt > 0 ? '2' : '1'
      this.ruleForm.need_receive_limit_cnt =
        defaultData.receive_limit_cnt > 0 ? '2' : '1'
      this.ruleForm.need_use_limit_content =
        defaultData.use_limit_content > 0 ? '2' : '1'
      this.ruleForm.is_public = defaultData.is_public + '' === '2'
      this.ruleForm.use_limit_original_price =
        defaultData.use_limit_original_price + '' === '2'
      if (defaultData.product_category && defaultData.product_category.length) {
        this.need_display_product_type = '商品分类'
      }
      if (defaultData.use_start_at && defaultData.use_end_at) {
        this.ruleForm.need_use_time = '1'
        this.ruleForm.use_at = this.getTimeRangeFormStr(
          defaultData.use_start_at,
          defaultData.use_end_at
        )
      } else {
        this.ruleForm.need_use_time = '2'
      }
      if (defaultData.receive_start_at && defaultData.receive_end_at) {
        this.ruleForm.need_receive_time = '2'
        this.ruleForm.receive_at = this.getTimeRangeFormStr(
          defaultData.receive_start_at,
          defaultData.receive_end_at
        )
      }
      this.zeroToByEmpty(this.ruleForm, [
        'discount_one_product',
        'one_product_use_cnt',
        'receive_limit_day',
        'receive_limit_cnt'
      ])
    },
    getTimeRangeFormStr(start, end) {
      return [start, end]
    },
    getTimeStr(time) {
      return time instanceof Date
        ? this.$utils.formatUnixTime(time.getTime() / 1000)
        : time
    },
    getStrFormTimeRange(times) {
      var start = ''
      var end = ''
      if (times.length === 2) {
        start = this.getTimeStr(times[0])
        end = this.getTimeStr(times[1])
      }
      return {
        start,
        end
      }
    },
    /// 开始日期不能小于当前事件
    checkTimeNeedLaterThenNow(time) {
      if (time instanceof Date === false) {
        time = new Date(time)
      }
      if (time < this.nowDay) {
        throw { message: '用券时间的开始时间不能早于今天' }
      }
    },
    // 获取【表单】的提交值
    getSubmitValue(submitData) {
      const use_limit_content =
        submitData.need_use_limit_content === '1'
          ? ''
          : submitData.use_limit_content
      const one_product_use_cnt =
        this.ruleForm.need_one_product_use_cnt === '1'
          ? ''
          : submitData.one_product_use_cnt // 每件产品限用券数
      const discount_one_product =
        this.need_discount_one_product && this.can_discount_one_product
          ? submitData.discount_one_product // 满减金额
          : ''
      const use_limit_original_price = submitData.use_limit_original_price
        ? '2'
        : '1'
      const is_public = submitData.is_public ? '2' : '1'
      let use_start_at = ''
      let use_end_at = ''
      let receive_limit_day = ''
      const receive_limit_cnt =
        this.ruleForm.need_receive_limit_cnt === '1'
          ? '0'
          : submitData.receive_limit_cnt
      let receive_start_at = ''
      let receive_end_at = ''
      if (this.ruleForm.need_use_time === '1') {
        if (!submitData.use_at) {
          throw { message: '请选择用券时间' }
        }
        const { start, end } = this.getStrFormTimeRange(submitData.use_at)
        use_start_at = start
        use_end_at = end
        receive_limit_day = 0
        if (!this.ruleForm.guid) {
          // 对于创建的表单
          this.checkTimeNeedLaterThenNow(start)
        }
      } else {
        if (!submitData.receive_limit_day) {
          throw { message: '请填写领取当日起多少天内可用' }
        }
        use_start_at = ''
        use_end_at = ''
        receive_limit_day = submitData.receive_limit_day
      }
      if (this.ruleForm.need_receive_time === '2') {
        const { start, end } = this.getStrFormTimeRange(submitData.receive_at)
        receive_start_at = start
        receive_end_at = end
      } else {
        receive_start_at = ''
        receive_end_at = ''
      }
      if (use_limit_content > 0 && use_limit_content < this.ruleForm.discount) {
        throw { message: '使用门槛不能小于满减金额' }
      }
      if (
        discount_one_product > 0 &&
        discount_one_product < this.ruleForm.discount
      ) {
        throw { message: '最高优惠金额不能小于优惠减免金额' }
      }
      return {
        use_limit_content,
        use_start_at,
        use_end_at,
        receive_limit_day,
        one_product_use_cnt,
        receive_limit_cnt,
        receive_start_at,
        receive_end_at,
        discount_one_product,
        use_limit_original_price,
        is_public,
        product:
          submitData.product.length > 0
            ? JSON.stringify(submitData.product)
            : '',
        product_category:
          submitData.product_category.length > 0
            ? JSON.stringify(submitData.product_category)
            : ''
      }
    },
    toSbumit() {
      let rightSubmitValue = {}
      try {
        rightSubmitValue = this.getSubmitValue(this.ruleForm)
      } catch (error) {
        this.$notify.error(error.message)
        return
      }
      this.$emit('submit', {
        ...this.ruleForm,
        ...rightSubmitValue
      })
    },
    submitForm(formName = 'ruleForm') {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.toSbumit()
        } else {
          return false
        }
      })
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName].resetFields()
    },
    async init() {
      this.sysList = await this.Apis.getSysList()
      if (Object.keys(this.$route.query).length) {
        // 编辑模式
        // 1. path?guid=guid
        // 2. path?params={"guid":"guid"}
        let params = this.$route.query
        if (params.params) {
          params = JSON.parse(params.params)
        }
        this.isLoading = true
        const data = await this.Apis.getCouponDetail(params)
        if (this.$route.name.includes('coupon-copy')) {
          // 复制模式
          data.guid = ''
        }
        this.getDefaulValue(data)
      } else {
        //新建模式
        this.ruleForm.use_sys = this.sysList[0].value
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang='scss' scoped></style>
